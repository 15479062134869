<template>
    <div class="mealre">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <div @click="handFromshow">
                <el-input
                size="small"
                class="dev_dowm"
                placeholder=""
                suffix-icon="el-icon-arrow-down"
                :readonly="true"
                >
                </el-input>
            </div>
        </div>
        <div class="attheadfrom" v-if="flag">
        <el-form
            :inline="true"
            size="small"
            :model="searchParams"
            class="demo-form-inline"
        >
            <el-form-item label="姓名">
                <el-input v-model="searchParams.name"></el-input>
            </el-form-item>

            <el-form-item label="部门">
            <Select @handleCheckChange="handleCheckChange" ></Select>
            </el-form-item>
            <el-form-item label="报餐时间">
                    <el-date-picker
                    v-model="searchParams.times"
                    type="datetimerange"
                    align="right"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd hh:mm"
                    :default-time="['12:00:00', '08:00:00']">
                    </el-date-picker>
            </el-form-item>

            <el-form-item label="工号">
                <el-input v-model="searchParams.number"></el-input>
            </el-form-item>

            <el-form-item label="手机号码">
                <el-input  v-model="searchParams.mobile"></el-input>
            </el-form-item>

            <el-form-item label="缺餐">
                <el-select v-model="searchParams.no_dinner" placeholder="请选择">
                    <el-option label="就餐" value="1"></el-option>
                    <el-option label="缺餐" value="0"></el-option>
                </el-select>
            </el-form-item>



            <el-form-item label="报餐类型">
                <el-checkbox-group v-model="searchParams.type">
                      <el-checkbox label="早餐" name="type"></el-checkbox>
                      <el-checkbox label="中餐" name="type"></el-checkbox>
                      <el-checkbox label="晚餐" name="type"></el-checkbox>
                      <el-checkbox label="宵夜" name="type"></el-checkbox>
                  </el-checkbox-group>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{
                    $t("devtable.cx")
                }}</el-button>
            </el-form-item>

        </el-form>
        </div>

        <Tables @handlerow="handlerow"
      @handlpages="handlpages"
      @handleSizeChange="handleSizeChange"
      :tableLabel="tableLabel"
      :tableData="tableData"
      :flags="flags"
    
      :configs="configs"></Tables>

    </div>
</template>

<script>
import Tables from '@/components/table'
import Select from '@/components/selectree/wltreeselect'
import {formatDate} from '@/utils/date.js'
export default {
    components:{
        Select,
        Tables
    },
    data(){
        return{
            values:[],
            flag:false,
            searchParams:{
                name:'',
                number:'',
                times:'',
                dept_id:'0',
                type:[],
                no_dinner:''
            },
            UserInfo:JSON.parse(window.localStorage.getItem('user')),
            tableLabel:[
            
            {
              prop: "user_id",
              label: '注册id',
              align: "center",
            },
            {
              prop: "name",
              label: '姓名',
              align: "center",
            },
            {
              prop: "number",
              label: '工号',
              align: "center",
            },
            {
              prop: "dept_name",
              label: '企业部门',
              align: "center",
            },
            
            {
              prop: "mobile",
              label: '手机号码',
              align: "center",
            },
            {
              prop: "open_time",
              label: '就餐日期',
              align: "center",
            },
            // {
            //   prop: "no_dinner",
            //   label: '缺餐',
            //   align: "center",
            // },
            {
              prop: "type",
              label: '报餐类型',
              align: "center",
            },
            ],
            tableData:[],
          
          // 分页数据
          configs: {
            page_index:1,
            pagesize: 10,
            total: 20,
            loading: false, // 加载状态
          },
          flags: {
            show: false, // 多选是否显示
            ishow: true, // 序号是否显示
          },
        }
    },
    mounted(){
      this.handleMelist()
    },
    methods:{
        handFromshow(){
            this.flag = !this.flag
        },
        // 用餐记录列表
        handleMelist(v,va){
          let type=[]
          if(this.searchParams.type){
            this.searchParams.type.forEach(el=>{
                if(el=='早餐'){
                    type.push('0')
                }
                if(el=='中餐'){
                    type.push('1')
                }
                if(el=='晚餐'){
                    type.push('2')
                }
                if(el=='宵夜'){
                    type.push('3')
                }
            })
          }

			
			// if(this.searchParams.times!=''&&){
			// 	this.searchParams.times[0] = 
			// 	this.searchParams.times[1] = 
			// }
			console.log(this.searchParams);
          let data={
            method:'co.dinner.record.list',
            page_index:v?v:1,
            page_size:va?va:10,
            agent_id:this.UserInfo.agent_id,
            dept_id:this.searchParams.dept_id,
            is_dinner_sys:this.UserInfo.is_dinner_sys,
            no_dinner:this.searchParams.no_dinner?this.searchParams.no_dinner:'-1',
            type:type.join(','),
            name:this.searchParams.name,
			number:this.searchParams.number,
            mobile:this.searchParams.mobile,
            start_time:new Date(this.searchParams.times[0]).getTime()/1000,
            end_time:new Date(this.searchParams.times[1]).getTime()/1000,
            enterprise_id:'0',
            dept_id:this.searchParams.dept_id
          }

          this.$serve(data).then(res=>{
            console.log(res)
            if(res.data.code==0){
              this.tableData = res.data.data
              this.configs.total = res.data.count
              this.tableData.forEach(el=>{
                el.open_time =  formatDate(new Date(el.open_time * 1000), 'yyyy-MM-dd hh:mm:ss')
                if(el.no_dinner=='0'){
                  el.no_dinner = '缺餐'
                }else if(el.no_dinner=='1'){
                  el.no_dinner = '就餐'
                }
                if(el.type=='0'){
                  el.type = '早餐'
                }else if(el.type=='1'){
                  el.type = '中餐'
                }else if(el.type=='2'){
                  el.type = '晚餐'
                }else if(el.type=='3'){
                  el.type = '宵夜'
                }else{
                  el.type = '缺餐'
                }
              })
            }
          })

        },

		



        // 获取企业部门id
        handleCheckChange(val){
            let dept_id=[]
            val.forEach(el=>{
                dept_id.push(el.id)
            })
            this.searchParams.dept_id = dept_id.join(",");
            console.log(`677`,this.searchParams.dept_id)
        },

        handlerow(){

        },

        onSubmit(){
          // console.log(this.searchParams)
          this.handleMelist()
        },
        // 分页
        handlpages(v){
          this.handleMelist(v)
        },
        handleSizeChange(va){
           this.handleMelist(1,va)
        },
    }
}
</script>

<style scoped>
.mealre{
    padding: 20px;
    background-color: #fff;
}
.dev_dowm{
    margin-left: 30px;
}
.attheadfrom {
    padding: 20px;
    margin-top: 10px;
}
</style>